import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d2f22f5c")
const _hoisted_1 = { class: "characteristic" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
    _createElementVNode("h4", null, _toDisplayString(_ctx.subtitle), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
  ]))
}
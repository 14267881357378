
import { defineComponent, PropType } from "vue";

import { API_Menu_Link } from "../../types";

export default defineComponent({
  props: {
    items: {
      type: Object as PropType<API_Menu_Link[]>,
      required: true,
    },
    itemsPerRow: {
      type: Number,
      default: 3,
    },
    menuPath: {
      type: String,
    },
  },
  computed: {
    isMegaMenu() {
      if (this.items === undefined) return false;
      return this.items
        .map((item) => item.items.length > 0)
        .every((v) => v === true);
    },
  },
});

import { createApp } from 'vue'
import App from '../../App.vue'

import { Button, Layout, Rate, Tooltip, Progress, Collapse, Input, Col, Row, Form, BackTop, Modal } from 'ant-design-vue';

const Vue = createApp(App);

Vue.use(Button);
Vue.use(Layout);
Vue.use(Rate);
Vue.use(Tooltip);
Vue.use(Progress)
Vue.use(Collapse)
Vue.use(Input)
Vue.use(Col)
Vue.use(Row)
Vue.use(BackTop)
Vue.use(Form)
Vue.use(Modal)
export default Vue


import { defineComponent, ref, watch } from "vue";
import FooterAboutUs from "../FooterAboutUs/FooterAboutUs.vue";
import FooterRecent from "../FooterRecent/FooterRecent.vue";
import FooterSubscribe from "../FooterSubscribe/FooterSubscribe.vue";
import fetchFooter from "../../misc/composition/fetchFooter";
import fetchBlogs from "../../misc/composition/fetchBlogs";
import fetchCasinos from "../../misc/composition/fetchCasinos";
export default defineComponent({
  components: { FooterAboutUs, FooterRecent, FooterSubscribe },
  setup() {
    const { footer, loading } = fetchFooter();
    const { blogs, loading: blogsLoading } = fetchBlogs(2);
    const { casinos, loading: casinosLoading } = fetchCasinos(2);

    let miniBlogs = ref();
    let miniCasinos = ref();
    watch(blogsLoading, () => {
      miniBlogs.value = blogs.value.map((blog) => {
        return {
          title: blog.title,
          href: {
            name: "Blog",
            params: {
              slug: blog.slug,
            },
          },
          thumbnail: blog.thumbnail,
          date: blog.publishedAt,
        };
      });
    });
    watch(casinosLoading, () => {
      miniCasinos.value = casinos.value.map((casino) => {
        return {
          title: casino.title,
          href: {
            name: "Casino",
            params: {
              slug: casino.slug,
            },
          },
          thumbnail: casino.cover,
          date: casino.publishedAt,
        };
      });
    });

    return {
      footer,
      loading,
      blogs,
      blogsLoading,
      miniBlogs,
      casinosLoading,
      miniCasinos,
    };
  },
});

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowAltCircleRight,
  faStar,
  faComment,
  faUser,
  faHeart,
  faEnvelope,
  faCalendar,
} from "@fortawesome/free-regular-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
library.add(
  faStar,
  faComment,
  faUser,
  faHeart,
  faArrowAltCircleRight,
  faAngleRight,
  faEnvelope,
  faCalendar
);


import { defineComponent, PropType } from "vue";
import Rating from "../Rating/Rating.vue";
import { Thumbnail } from "../../types";
type CustomBadge = {
  positionNo: Number;
  type: "ribbon" | "default";
};
export default defineComponent({
  components: { Rating },
  props: {
    title: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: Object as PropType<Thumbnail>,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    badge: {
      type: Object as PropType<CustomBadge>,
    },
    offer: {
      type: String,
    },
    reward: {
      type: String,
    },
    /** Must be between 1 and 5 */
    rating: {
      type: Number,
      default: 1,
      validator(value: Number) {
        return value <= 5 || value >= 1;
      },
    },
    href: {
      type: [String, Object],
      default: "/",
      required: true,
    },
  },
});


import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
    description: {
      type: String,
      required: true,
    },
    secondaryText: {
      type: String,
    },
    href: {
      type: String,
      default: "/",
      required: true,
    },
    buttonName: {
      type: String,
    },
    hideImages: {
      type: Boolean,
      default: false,
      required: false
    },
  },
});

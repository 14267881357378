import { getSingle } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import {ref} from "vue";
import { Image } from "../../types";
import { Link } from "../../types"; 

type HomeSectionWithImage = {
  title:string,
  subtitle:string,
  description:string,
  href:Link,
  thumbnail:Image[],
}

export default function fetchSectionWithImage() {
  const section = ref<HomeSectionWithImage>({} as HomeSectionWithImage);
  const loading = ref<boolean>(true);
  const fetchSection= () => {
    getSingle<HomeSectionWithImage>(GET.HOME_SECTION_WITH_IMAGE).then((result) => {
      loading.value = false;
      section.value = camelizeKeys(result);
    });
  };
  fetchSection();
  return{section,loading,fetchSection}
}


import { defineComponent, PropType } from "vue";
import { Thumbnail } from "../../types";
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: Object as PropType<Thumbnail>,
      required: true,
    },
    excerpt: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    href: {
      type: [String, Object],
      default: "/",
      required: true,
    },
    offer: {
      type: String,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
});

import { find, meanBy } from "lodash";
import { currentCountry } from "../utilis/handleUserLocation";
import {
  Casino,
  Rating,
  ProCons,
  Tag,
  API_CustomEnumeration,
} from "../../types";
import { computed, ComputedRef, Ref } from "vue";

export default function (casino: Ref<Casino>) {
  const canUserPlay: ComputedRef<boolean> = computed(function (): boolean {
    if (
      casino.value.tags === null ||
      casino.value.tags.restrictedCountries === null
    ) {
      return true;
    }
    return (
      find(
        casino.value.tags.restrictedCountries,
        (obj: Tag) => obj.code !== currentCountry
      ) !== undefined
    );
  });

  const calcRating: ComputedRef<number> = computed((): number => {
    if (!casino.value.review) {
      return 1;
    }
    return Math.round(
      meanBy(casino.value.review.rating, (p: Rating) => p.percentage) / 20
    );
  });

  const getProCons: ComputedRef<object> = computed(
    (): ProCons => {
      const pros: string[] = Object.keys(casino.value.experiences).map(
        (key: any) => (casino.value.experiences[key] as any).pros
      );
      const cons: string[] = Object.keys(casino.value.experiences).map(
        (key: any) => (casino.value.experiences[key] as any).cons
      );

      return { pros: pros, cons: cons };
    }
  );

  const getFeatures: ComputedRef<string[]> = computed((): string[] =>
    casino.value.features.map(function (feature) {
      return feature.text;
    })
  );

  const fCanUserPlay = (restrictedCountries: Tag[]): boolean =>
    find(restrictedCountries, (obj: Tag) => obj.code !== currentCountry) !==
    undefined;

  const fCalcRating = (rating: Rating[]): number => {
    return Math.round(meanBy(rating, (p: Rating) => p.percentage) / 20);
  };

  const fGetProCons = (experiences: []): ProCons => {
    const pros: string[] = Object.keys(experiences).map(
      (key: any) => (experiences[key] as any).pros
    );
    const cons: string[] = Object.keys(experiences).map(
      (key: any) => (experiences[key] as any).cons
    );

    return { pros: pros, cons: cons };
  };

  const fGetFeatures = (features: API_CustomEnumeration[]): string[] =>
    features.map(function (feature) {
      return feature.text;
    });

  return {
    casino,
    canUserPlay,
    calcRating,
    getProCons,
    getFeatures,
    fGetFeatures,
    fGetProCons,
    fCanUserPlay,
    fCalcRating,
  };
}

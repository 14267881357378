import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0526d710")
const _hoisted_1 = { class: "text-center card" }
const _hoisted_2 = { class: "wpb_wrapper" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "wpb_wrapper" }
const _hoisted_5 = ["innerHTML"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_font_awesome_icon, {
        icon: [_ctx.icon.type, _ctx.icon.name],
        class: "icon"
      }, null, 8, ["icon"]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", { innerHTML: _ctx.description }, null, 8, _hoisted_5),
      _createVNode(_component_router_link, {
        style: {"font-weight":"bold","color":"#389d29"},
        to: _ctx.link.href
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.link.name), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}

import { defineComponent } from "vue";
import CardCasinoOnline from "../../components/CardCasinoOnline/CardCasinoOnline.vue";
import fetchHomeOnlineCasino from "../../misc/composition/fetchHomeNewOnlineCasino";
import SectionHeading from "../../components/SectionHeading/SectionHeading.vue";
import fetchHomeTitles from "../../misc/composition/fetchHomeSectionTitles";
export default defineComponent({
  name: "Home",
  components: {
    CardCasinoOnline,
    SectionHeading,
  },
  setup() {
    //
    const { newcasinos, loading } = fetchHomeOnlineCasino();
    const { titles, loading: titleLoading } = fetchHomeTitles();
    return { newcasinos, loading, titleLoading, titles };
  },
});

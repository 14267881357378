
import { defineComponent, Ref } from "vue";
import CardCasinoOffer from "../../components/CardCasinoOffer/CardCasinoOffer.vue";
import fetchHomeTitles from "../../misc/composition/fetchHomeSectionTitles";
import fetchHomeCasinoByID from "../../misc/composition/fetchHomeCasinoByID";
import SectionHeading from "../../components/SectionHeading/SectionHeading.vue";
import casinoHelpers from "../../misc/composition/casinoHelpers";
import { Casino } from "../../types";
export default defineComponent({
  name: "Home",
  components: {
    CardCasinoOffer,
    SectionHeading,
  },
  setup() {
    const { casinos, loading } = fetchHomeCasinoByID();
    const { titles, loading: titleLoading } = fetchHomeTitles();
    const { fCalcRating } = casinoHelpers({} as Ref<Casino>);
    return { casinos, loading, titleLoading, titles, fCalcRating };
  },
});

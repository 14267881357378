import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/casino/:slug",
    name: "Casino",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "casino" */ "../views/Casino.vue"),
  },
  {
    path: "/list-:component/:category/:slug",
    name: "List",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "list" */ "../views/List.vue"),
  },
  {
    path: "/blog/:slug",
    name: "Blog",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "blog" */ "../views/Blog.vue"),
  },
  {
    path: "/blog-list/:type?/:slug?",
    name: "BlogList",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "blog-list" */ "../views/BlogList.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/about",
    name: "AboutUs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/review/:slug",
    name: "Review",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "review" */ "../views/Review.vue"),
  },
  {
    path: "/slot/:slug",
    name: "Slot",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "slot" */ "../views/Slot.vue"),
  },
  {
    path: "/bookmaker/:slug",
    name: "Bookmaker",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "bookmaker" */ "../views/Bookmaker.vue"),
  },
  {
    path: "/bookmakers",
    name: "BookmakerTable",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "bookmakertable" */ "../views/BookmakerTable.vue"
      ),
  },
  {
    path: "/bonus/:slug",
    name: "Bonus",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "bonus" */ "../views/Bonus.vue"),
  },
  {
    path: "/casino-landing-page/",
    name: "CasinoLandingPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "clanding" */ "../views/HomeCasinoLanding.vue"
      ),
  },
  {
    path: "/home-video-poker/",
    name: "HomeVideoPoker",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "vpoker" */ "../views/HomeVideoPoker.vue"),
  },
  {
    path: "/home-casino-affiliate/",
    name: "HomeCasinoAffiliate",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "caffiliate" */ "../views/HomeCasinoAffiliate.vue"
      ),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
  {
    path: "/home-blogging",
    name: "HomeBlogging",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "hblogging" */ "../views/HomeBlogging.vue"),
  },
  {
    path: "/home-bookmakers",
    name: "HomeBookmakers",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "hbookmakers" */ "../views/HomeBookmakers.vue"
      ),
  },
  {
    path: "/home-horse-betting",
    name: "HomeHorseBetting",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "hbetting" */ "../views/HomeHorseBetting.vue"
      ),
  },
  {
    path: "/home-esports-betting",
    name: "HomeEsportsBetting",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/HomeEsportsBetting.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.VUE_APP_PATH),
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-764e1725")
const _hoisted_1 = {
  key: 2,
  class: "mt-item-reviews-average-wrapper"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "rating" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "rating" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["stars score d-flex", [_ctx.getClass, _ctx.position]])
  }, [
    (_ctx.limitToOneStar)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          class: "stars",
          icon: ['fas', 'star']
        }))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.maxStars, (star) => {
          return (_openBlock(), _createBlock(_component_font_awesome_icon, {
            class: "stars",
            key: star,
            icon: [star <= _ctx.getRatingAvg ? 'fas' : 'far', 'star']
          }, null, 8, ["icon"]))
        }), 128)),
    (_ctx.hasRating || _ctx.hasRatingOutOf)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.hasRating)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getRatingAvg), 1)
              ]))
            : (_ctx.hasRatingOutOf)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getRatingAvg) + " / " + _toDisplayString(_ctx.maxStars), 1)
                ]))
              : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
import { get } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { API_Blog } from "../../types";
import { ref, computed, ComputedRef } from "vue";
// import { find, meanBy } from "lodash";

export default function fetchLatestNews(limit: number = 3) {
  const latestNews = ref<API_Blog[]>([]);
  const loading = ref<boolean>(true);

  const fetchLatestNews = (): Promise<void | API_Blog> => {
    return get<API_Blog[]>(GET.BLOG, {
      _sort: "published_at:DESC",
      _limit: limit,
    }).then((result) => {
      loading.value = false;
      latestNews.value = camelizeKeys(result);
    });
  };

  fetchLatestNews();

  return {
    latestNews,
    fetchLatestNews,
    loading,
  };
}

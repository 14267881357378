
import {
    defineComponent,
    PropType
} from "vue";

import {
    CustomLink
} from './CardInfo'
import {
    Icon
} from '@/types'

export default defineComponent({
    props: {
        title: {
            type: String,
            required: true
        },
        /** USES V-HTML !! */
        description: {
            type: String,
            required: true
        },
        /** Must be imported in utils/icons/{typeOfIcon}.js */
        icon: {
            type: Object as PropType < Icon > ,
            required: true
        },
        link: {
            type: Object as PropType < CustomLink > ,
            required: true
        },
    }
})

import { get } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import {

  Casino,
} from "../../types";
import { ref, computed, ComputedRef } from "vue";
import qs from "qs";

export default function (limit: number = 10) {
  const casinos = ref<Casino[]>([]);
  const loading = ref<boolean>(true);

  const fetchCasinos = (): Promise<void | Casino[]> => {
    return get<Casino[]>(GET.CASINOS, {
      _limit: limit,
      _sort: "published_at:DESC",
    }).then((result) => {
      loading.value = false;
      casinos.value = camelizeKeys(result);
    });
  };

  fetchCasinos();

  return {
    casinos,
    fetchCasinos,
    loading,
  };
}

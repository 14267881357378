import { createStore } from 'vuex'

import createPersistedState from "vuex-persistedstate";

import {user} from './user'

export const dataState = createPersistedState({
  paths: ['user'] // modules you want to save state for
})

export default createStore({
  modules: {user},
  plugins: [dataState],
  strict: process.env.NODE_ENV !== "production",
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-48efefde")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_CardNews = _resolveComponent("CardNews")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.titleLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_SectionHeading, {
                class: "section-heading",
                title: _ctx.titles.section[2].title,
                subtitle: _ctx.titles.section[2].subtitle,
                description: _ctx.titles.section[2].description
              }, null, 8, ["title", "subtitle", "description"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_a_row, {
          justify: "center",
          type: "flex",
          gutter: [25, 25]
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.latestNews, (item) => {
              return (_openBlock(), _createBlock(_component_a_col, {
                xl: 8,
                xs: 24,
                key: item
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CardNews, {
                    class: "card-news mb-1 mb-md-0",
                    title: item.title,
                    description: item.description,
                    author: { name: 'Trader Marcus', href: '/' },
                    href: {
            name: 'Blog',
            params: {
              slug: item.slug,
            },
          }
                  }, null, 8, ["title", "description", "href"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-ce5f98d6")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "section-title" }
const _hoisted_3 = { key: 1 }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_CardCasinoOffer = _resolveComponent("CardCasinoOffer")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.titleLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              _createVNode(_component_SectionHeading, {
                class: "section-heading",
                title: _ctx.titles.section[0].title,
                subtitle: _ctx.titles.section[0].subtitle,
                description: _ctx.titles.section[0].description
              }, null, 8, ["title", "subtitle", "description"])
            ], {}, true)
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_a_row, {
            justify: "center",
            type: "flex",
            gutter: [30, 30]
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.casinos, (item) => {
                return (_openBlock(), _createBlock(_component_a_col, {
                  xl: 6,
                  xs: 24,
                  key: item
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CardCasinoOffer, {
                      class: "card-casino",
                      thumbnail: item.thumbnail,
                      title: item.title,
                      description: item.description,
                      rating: item.review === null ? 0 : _ctx.fCalcRating(item.review.rating),
                      href: {
            name: 'Casino',
            params: {
              slug: item.slug,
            },
          },
                      offer: item.termsAndConditions,
                      reward: item.excerpt
                    }, null, 8, ["thumbnail", "title", "description", "rating", "href", "offer", "reward"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
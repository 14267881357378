import { getSingle } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import {ref} from "vue";
import { Image } from "../../types";
import { Link } from "../../types"; 

type HomeHeader = {
  title:string,
  subtitle:string,
  description:string,
  secondarytext:string,
  href:Link,
  image:Image,
}

export default function fetchHomeHeader() {
  const header = ref<HomeHeader>({} as HomeHeader);
  const loading = ref<boolean>(true);
  const fetchHeader= () => {
    getSingle<HomeHeader>(GET.HOME_HEADER).then((result) => {
      loading.value = false;
      header.value = camelizeKeys(result);
    });
  };
  fetchHeader();
  return{header,loading,fetchHeader}
}

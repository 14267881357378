import { getSingle } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import {ref} from "vue";
import { Image } from "../../types";
import { Link, Icon } from "../../types"; 

type HomeInfo = {

 sectionTitle: {
  title: String;
  subtitle: String;
  description: String;
};
}

export default function fetchHomeInfo() {
  const info = ref<HomeInfo>({} as HomeInfo);
  const loading = ref<boolean>(true);
  const fetchInfo= () => {
    getSingle<HomeInfo>(GET.HOME_GET_IN_TOUCH).then((result) => {
      loading.value = false;
      info.value = camelizeKeys(result);
    });
  };
  fetchInfo();
  return{info,loading,fetchInfo}
}

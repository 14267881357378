
import { defineComponent, PropType } from "vue";

import { Author, Thumbnail } from "../../types";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    date: {
      type: Date,
      default: function () {
        return new Date();
      },
      required: false,
    },
    description: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: Object as PropType<Thumbnail>
    },
    href: {
      type: [String, Object],
      default: "/",
      required: true,
    },
    author: {
      type: Object as PropType<Author>,
      required: true,
    },
  },
});

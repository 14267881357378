import { get } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import {
  Casino,
  AcceptedCountries,
  Rating,
  ProCons,
  Tag,
  API_Blog,
} from "../../types";
import { ref, computed, ComputedRef } from "vue";
import qs from "qs";

export default function (limit: number = 10) {
  const blogs = ref<API_Blog[]>([]);
  const loading = ref<boolean>(true);

  const fetchBlogs = (): Promise<void | Casino[]> => {
    return get<API_Blog[]>(GET.BLOG, {
      _limit: limit,
      _sort: "published_at:DESC",
    }).then((result) => {
      loading.value = false;
      blogs.value = camelizeKeys(result);
    });
  };

  fetchBlogs();

  return {
    blogs,
    fetchBlogs,
    loading,
  };
}


import { defineComponent } from "vue";
import CardCashBack from "../../components/CardCashBack/CardCashBack.vue";
import fetchHomeBonus from "../../misc/composition/fetchHomeBonus";
import fetchHomeTitles from "../../misc/composition/fetchHomeSectionTitles";
import SectionHeading from "../../components/SectionHeading/SectionHeading.vue";
export default defineComponent({
  name: "Home",
  components: {
    CardCashBack,
    SectionHeading,
  },
  setup() {
    const { bonuses, loading } = fetchHomeBonus();
    const { titles, loading: titleLoading } = fetchHomeTitles();
    return { bonuses, loading, titleLoading, titles };
  },
});

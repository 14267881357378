import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d9ebdf20")
const _hoisted_1 = { class: "home-info center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "section-title" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_CardInfo = _resolveComponent("CardInfo")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SectionHeading, {
              class: "section-info-title",
              title: _ctx.info.sectionTitle.title,
              subtitle: _ctx.info.sectionTitle.subtitle,
              description: _ctx.info.sectionTitle.description
            }, null, 8, ["title", "subtitle", "description"])
          ]),
          _createVNode(_component_a_row, {
            class: "card-row",
            justify: "center",
            type: "flex",
            gutter: [25, { xs: 0, sm: 0, md: 25, lg: 25 }]
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info.content, (item) => {
                return (_openBlock(), _createBlock(_component_a_col, {
                  class: "card info-cards",
                  xl: 8,
                  xs: 24,
                  key: item
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CardInfo, {
                      title: item.title,
                      description: item.description,
                      icon: {
              type: _ctx.convertTextToIconType(item.icon[0].type),
              name: item.icon[0].name,
            },
                      link: item.link
                    }, null, 8, ["title", "description", "icon", "link"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
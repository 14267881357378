import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-33c97582")
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  class: "blog-info-wrapper"
}
const _hoisted_3 = {
  key: 0,
  class: "category"
}
const _hoisted_4 = { class: "date hide-on-mobile" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = {
  key: 0,
  class: "description"
}
const _hoisted_7 = {
  key: 1,
  class: "d-flex author-details"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("article", {
    class: _normalizeClass({ mini: _ctx.isMini, compact: _ctx.isCompact, card: !_ctx.isCompact })
  }, [
    (_ctx.thumbnail)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["post-thumbnail", { centerV: _ctx.isCompact }])
        }, [
          _createVNode(_component_router_link, { to: _ctx.href }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.$getFileSource(_ctx.thumbnail.url)
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          }, 8, ["to"])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["content w-100", { 'px-0 py-1': _ctx.isCompact }])
    }, [
      (!_ctx.isCompact)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.isMini)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.details.category), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$formatDate(_ctx.details.date)), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", null, [
          _createVNode(_component_router_link, { to: _ctx.href }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        (_ctx.isNormal)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.description), 1))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.isMini)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("p", null, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['far', 'user'],
                class: "icon"
              }),
              _createVNode(_component_router_link, {
                to: _ctx.author.href
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.author.name), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("p", null, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['far', 'comment'],
                class: "icon"
              }),
              _createVNode(_component_router_link, { to: _ctx.href }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.details.commentNo), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}
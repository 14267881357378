import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_testimonial = _resolveComponent("testimonial")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, {
    "slides-per-view": 1,
    centeredSlides: true,
    spaceBetween: 30,
    grabCursor: true,
    pagination: {
  "clickable": true
}
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_swiper_slide, { key: item }, {
          default: _withCtx(() => [
            _createVNode(_component_testimonial, _mergeProps(item, { class: "bullets" }), null, 16)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}
export enum GET {
  CASINOS = "casinos",
  BLOG = "blogs",
  BONUSES = "bonuses",
  NAV_LINKS = "nav-links",
  HOME_HEADER = "home-header",
  COMMENTS = "comments",
  HOME_FAQ = "home-faq",
  HOME_CHARACTERISTICS = "home-characteristics",
  HOME_PARTNERS = "home-partners",
  HOME_TESTIMONIALS = "home-testimonials",
  HOME_GET_IN_TOUCH = "home-get-in-touch",
  SLOTS = "slots",
  TAG_CURRENCIES = "currencies",
  TAG_CATEGORIES = "categories",
  TAG_LANGUAGES = "languages",
  TAG_LICENCES = "licences",
  TAG_PAYMENT_METHODS = "payment-methods",
  TAG_SOFTWARES = "softwares",
  TAG_RESTRICTED_COUNTRIES = "restricted-countries",
  BLOG_TAGS = "blog-tags",
  ALL_COMMENTS = "comments/moderation/all",
  CONTACT = "contact",
  BLOG_CATEGORIES = "blog-categories",
  REVIEW = "reviews",
  BOOKMAKERS = "bookmakers",
  HOME_SECTION_WITH_IMAGE = "home-section-with-image",
  HOME_SECTIONS = "home-sections",
  ABOUT_OFFER = "about-aboutoffer",
  FOOTER = "footer",
  MENU = "menu",
  MENU_LINKS = "navigation/render/main-navigation",
  SLOT_TAGS = "slot-categories",
  BONUS_TAGS = "bonus-categories",
  LATEST_COMMENTS_BLOG= "comments-blog"
}

export enum POST {
  COMMENT = "comments",
  LIKE_CASINO = "like-casino",
  LIKE_BOOKMAKER = "like-bookmaker",
}

export enum DELETE {}

export enum PUT {}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2b27d491")
const _hoisted_1 = { class: "p-0" }
const _hoisted_2 = { class: "thumbnail position-relative" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "thumbnail-overlay position-absolute position-absolute-stretch center" }
const _hoisted_5 = { class: "date" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("aside", null, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "d-flex article",
          key: post
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_link, {
              to: post.href
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: _ctx.$getFileSource(post.thumbnail.url),
                  alt: post.title,
                  class: "w-100 h-100"
                }, null, 8, _hoisted_3),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fas', 'plus'],
                    class: "icon"
                  })
                ])
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_router_link, {
              class: "title",
              to: post.href
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(post.title), 1)
              ]),
              _: 2
            }, 1032, ["to"]),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$formatDate(post.date)), 1)
          ])
        ]))
      }), 128))
    ])
  ]))
}
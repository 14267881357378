
import { defineComponent, PropType } from "vue";
import { Rating, Position } from "../../types";
import { meanBy } from "lodash";
export default defineComponent({
  props: {
    hasRating: {
      type: Boolean,
      required: false,
    },
    hasRatingOutOf: {
      type: Boolean,
      required: false,
    },
    limitToOneStar: {
      type: Boolean,
      required: false,
    },
    rating: {
      type: Number,
      default: 1,
      validator(value: Number) {
        return value <= 5 || value >= 1;
      },
    },
    ratings: {
      type: Object as PropType<Rating[]>,
      required: false,
    },
    maxStars: {
      type: Number,
      required: false,
      default: 5,
    },
    position: {
      type: Object as PropType<Position>,
      default: () => {
        return { none: "none" };
      },
    },
  },
  computed: {
    getRatingAvg(): number {
      if (this.rating) {
        return this.rating;
      }
      return Math.round(
        (meanBy(this.ratings, (p) => p.percentage) / 10 / 10) * this.maxStars
      );
    },
    getClass(): string {
      return "level-bg-" + (this.getRatingAvg > 5 ? 5 : this.getRatingAvg);
    },
  },
});

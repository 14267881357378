export default function convertTextToIconType(iconType: string): string {
  if (iconType == "Solid") {
    return "fas";
  } else if (iconType == "Bussiness") {
    return "fab";
  } else if (iconType == "Regular") {
    return "far";
  }

  return "fas";
}

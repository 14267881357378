import { get,getSingle } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { Bonus} from "../../types";
import { ref, computed, ComputedRef } from "vue";
import { find, meanBy } from "lodash";

export default function fetchHomeBonuses(limit: number=4) {
    const bonuses = ref<Bonus>({} as Bonus);
    const loading = ref<boolean>(true);
  
    const fetchBonuses = () : Promise<void | Bonus> => {
      return get<Bonus>(GET.BONUSES,{"_limit": limit}).then((result) => {
        loading.value = false;
        bonuses.value = camelizeKeys(result);
      });
    };

    
  
    fetchBonuses();
  
    return {
      bonuses,
      fetchBonuses,
      loading
    };
  }
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0659bf88")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_CardCasinoOnline = _resolveComponent("CardCasinoOnline")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.titleLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_SectionHeading, {
                class: "section-heading",
                title: _ctx.titles.section[3].title,
                subtitle: _ctx.titles.section[3].subtitle,
                description: _ctx.titles.section[3].description
              }, null, 8, ["title", "subtitle", "description"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_a_row, {
          justify: "center",
          type: "flex",
          gutter: [30, 30]
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newcasinos, (item) => {
              return (_openBlock(), _createBlock(_component_a_col, {
                xl: 12,
                xs: 24,
                key: item
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CardCasinoOnline, {
                    class: "",
                    title: item.title,
                    thumbnail: item.thumbnail,
                    excerpt: item.excerpt,
                    offer: item.termsAndConditions,
                    href: {
              name: 'Casino',
              params: {
                  slug: item.slug
              }
          }
                  }, null, 8, ["title", "thumbnail", "excerpt", "offer", "href"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}

import {
    defineComponent,
    PropType
} from "vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";

// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from 'swiper';
import {Testimonial as TestimonialType} from '../Testimonial/Testimonial'
// install Swiper modules
SwiperCore.use([Pagination]);
import 'swiper/swiper-bundle.min.css';

// Import Swiper styles
import 'swiper/swiper.min.css';
import Testimonial from "../Testimonial/Testimonial.vue";
export default defineComponent({
    components: {
        Swiper,
        SwiperSlide,
        Testimonial,
    },
    props: {
        items: {
            type: Object as PropType<TestimonialType[]>
        }
    }
})

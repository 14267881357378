import { getSingle } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { computed, ComputedRef, ref } from "vue";
import fromPairs from "lodash";
import { Image } from "@/types";
type API_Partners = {
  slider: Image[];
};
export default function fetchHomeCharacteristics() {
  const carousel = ref<API_Partners>({} as API_Partners);
  const loading = ref<boolean>(true);

  const fetchSectionHeading = () => {
    getSingle<API_Partners>(GET.HOME_PARTNERS).then((result) => {
      loading.value = false;
      carousel.value = result;
      const hashmap = fromPairs(
        carousel.value.slider.map(function (item: any) {
          return [item.url];
        })
      );
    });
  };
  const getUrl: ComputedRef<object> = computed((): string[] =>
    carousel.value.slider.map(function (item: Image) {
      return item.url;
    })
  );
  fetchSectionHeading();

  return { carousel, loading, fetchSectionHeading, getUrl };
}

// import styles
import 'ant-design-vue/dist/antd.css';
import './assets/css/main.css'
import './assets/css/utils.css'
import 'bootstrap/dist/css/bootstrap-utilities.min.css'

// import plugins
import router from './router'
import store from './misc/utilis/store'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// import custom plugins
import DateFormatter from  './misc/helpers/DateFormatter'
import CurrencyFormatter from  './misc/helpers/CurrencyFormatter'
import FileSource from  './misc/helpers/FileSource'
import Marked from  './misc/helpers/Marked'

// import ant components alongside the vue app
import Vue from './misc/utilis/antComponents';

// import icons 
import "./misc/utilis/icons/iconsBrands"
import "./misc/utilis/icons/iconsRegular"
import "./misc/utilis/icons/iconsSolid"

// register plugins
Vue.use(DateFormatter);
Vue.use(CurrencyFormatter);
Vue.use(FileSource);
Vue.use(Marked);

Vue
.use(store)
.use(router)
.component("font-awesome-icon", FontAwesomeIcon)

Vue.mount('#app')


import { defineComponent } from "vue";
import MainHeader from "../components/MainHeader/MainHeader.vue";
import LoadingPageAnim from "../components/LoadingPageAnim.vue";
import fetchHomeHeader from "../misc/composition/fetchHomeHeader";
import fetchCarouselPartners from "../misc/composition/fetchCarouselPartners";
import fetchTestimonialSlider from "../misc/composition/fetchTestimonialSlider";
import CarouselPartners from "../components/CarouselPartners/CarouselPartners.vue";
import TestimonialSlider from "../components/TestimonialSlider/TestimonialSlider.vue";
import SectionHomeCharacteristics from "../components/SectionHomeCharacteristics/SectionHomeCharacteristics.vue";
import SectionHomeCasino from "../components/SectionHomeCasinos/SectionHomeCasino.vue";
import SectionHomeSlots from "../components/SectionHomeSlots/SectionHomeSlots.vue";
import SectionWithImage from "../components/SectionWithImage/SectionWithImage.vue";
import SectionHomeBonuses from "../components/SectionHomeBonuses/SectionHomeBonuses.vue";
import SectionHomeNews from "../components/SectionHomeNews/SectionHomeNews.vue";
import SectionNewOnlineCasinos from "../components/SectionNewOnlineCasinos/SectionNewOnlineCasinos.vue";
import SectionHomeGridNews from "../components/SectionHomeGridNews/SectionHomeGridNews.vue";
import SectionHomeInfo from "../components/SectionHomeInfo/SectionHomeInfo.vue";
import Jumbotron from "../components/Jumbotron/Jumbotron.vue";
import fetchSectionWithImage from "../misc/composition/fetchHomSectionWithImage";
import FAQ from "../components/FAQ/FAQ.vue";
import fetchHomeFAQ from "../misc/composition/fetchHomeFAQ";

export default defineComponent({
  name: "Home",
  components: {
    MainHeader,
    CarouselPartners,
    TestimonialSlider,
    SectionHomeCharacteristics,
    SectionHomeCasino,
    SectionHomeSlots,
    SectionWithImage,
    SectionHomeBonuses,
    SectionHomeNews,
    SectionNewOnlineCasinos,
    SectionHomeGridNews,
    Jumbotron,
    FAQ,
    SectionHomeInfo,
    LoadingPageAnim,
  },
  setup() {
    //
    const { header, loading } = fetchHomeHeader();
    const { section, loading: sectionLoading } = fetchSectionWithImage();
    const {
      carousel,
      loading: carouselLoading,
      getUrl,
    } = fetchCarouselPartners();
    const {
      testimonial,
      loading: loadingTestimonial,
    } = fetchTestimonialSlider();
    document.title = "Home - Coinflip";

    const { faq, loading: loadingFaq } = fetchHomeFAQ();

    return {
      fetchHomeHeader,
      testimonial,
      loadingTestimonial,
      carousel,
      loading,
      header,
      carouselLoading,
      getUrl,
      section,
      sectionLoading,
      faq,
      loadingFaq,
    };
  },
});

import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0937a662")
const _hoisted_1 = {
  key: 0,
  class: "subtitle"
}
const _hoisted_2 = { class: "section-title dark_title" }
const _hoisted_3 = {
  key: 1,
  class: "description hide-on-mobile"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["heading", { removeBorderBottom: _ctx.removeBorderBottom }])
  }, [
    (_ctx.subtitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.subtitle), 1))
      : _createCommentVNode("", true),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true)
  ], 2))
}
import { get, getSingle } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { Casino } from "../../types";
import { ref, computed, ComputedRef } from "vue";
import { find, meanBy } from "lodash";
export default function fetchHomeCasinoByID(limit: number = 8) {
  const casinos = ref<Casino[]>([]);
  const loading = ref<boolean>(true);

  const fetchCasino = (): Promise<void | Casino[]> => {
    return get<Casino[]>(GET.CASINOS, { _limit: limit }).then((result) => {
      loading.value = false;
      casinos.value = camelizeKeys(result);
    });
  };
  fetchCasino();

  return {
    casinos,
    fetchCasino,
    loading,
  };
}


import { defineComponent, PropType } from "vue";

import { BlogDetails, Author, Thumbnail } from "../../types";

export default defineComponent({
  props: {
    type: {
      type: String as PropType<"mini" | "compact" | "normal">,
      default: () => {
        "normal";
      },
    },
    thumbnail: {
      type: Object as PropType<Thumbnail>,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    href: {
      type: [String, Object],
      default: "/",
      required: true,
    },
    author: {
      type: Object as PropType<Author>,
      required: true,
    },
    details: {
      type: Object as PropType<BlogDetails>,
      required: true,
    },
  },
  computed: {
    isMini() {
      return this.type === "mini";
    },
    isNormal() {
      return this.type === "normal";
    },
    isCompact() {
      return this.type === "compact";
    },
  },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-fae2bcc0")
const _hoisted_1 = {
  key: 0,
  class: "submenu-vertical"
}
const _hoisted_2 = { class: "fw-bold text-uppercase text-dark" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["position-absolute", { isMegaMenu: _ctx.isMegaMenu }])
  }, [
    (_ctx.items !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Math.ceil(_ctx.items.length / _ctx.itemsPerRow), (rowIdx) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["d-flex wrapper", { 'flex-column': !_ctx.isMegaMenu }]),
              key: rowIdx
            }, [
              (_ctx.isMegaMenu)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.items.slice(
              _ctx.itemsPerRow * (rowIdx - 1),
              _ctx.itemsPerRow * rowIdx
            ), (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "d-inline-flex flex-column mb-3 col-wrapper",
                      key: item
                    }, [
                      _createElementVNode("h6", _hoisted_2, _toDisplayString(item.title), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (link) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "item-group",
                          key: link
                        }, [
                          (link.type === 'INTERNAL')
                            ? (_openBlock(), _createBlock(_component_router_link, {
                                key: 0,
                                to: link.path.replace(_ctx.menuPath, '')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(link.title), 1)
                                ]),
                                _: 2
                              }, 1032, ["to"]))
                            : (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                class: "item-group",
                                href: link.path,
                                target: "_blank"
                              }, _toDisplayString(link.title), 9, _hoisted_3))
                        ]))
                      }), 128))
                    ]))
                  }), 128))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.items, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "d-flex flex-column liunk",
                      key: item
                    }, [
                      (item.type === 'INTERNAL')
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 0,
                            class: "p-0 item-group",
                            to: item.path.replace(_ctx.menuPath, '')
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.title), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                        : (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: item.path,
                            target: "_blank",
                            class: "p-0 item-group"
                          }, _toDisplayString(item.title), 9, _hoisted_4))
                    ]))
                  }), 128))
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-456e8505")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_CardBlogPost = _resolveComponent("CardBlogPost")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.titleLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_SectionHeading, {
                class: "section-heading",
                title: _ctx.titles.section[4].title,
                subtitle: _ctx.titles.section[4].subtitle,
                description: _ctx.titles.section[4].description
              }, null, 8, ["title", "subtitle", "description"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_a_row, { gutter: 23 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              class: "big-col",
              xl: 16,
              xs: 24
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CardBlogPost, {
                  class: "mb-4 mb-md-0 main-new",
                  type: 'normal',
                  title: _ctx.latestNews[0].title,
                  thumbnail: _ctx.latestNews[0].thumbnail,
                  description: _ctx.latestNews[0].description,
                  tag: { name: '/', href: '/' },
                  author: { name: _ctx.latestNews[0].author.firstname + '  ' +  _ctx.latestNews[0].author.lastname, href: '/' },
                  details: {
            date: Date.parse(_ctx.latestNews[0].publishedAt),
            commentNo: _ctx.latestNews[0].comments.length,
          },
                  href: {
            name: 'Blog',
            params: {
              slug: _ctx.latestNews[0].slug,
            },
          }
                }, null, 8, ["title", "thumbnail", "description", "author", "details", "href"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              xl: 8,
              xs: 24,
              class: "d-flex flex-column justify-content-between small-col"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.latestNews.slice(1, 4), (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "mini-card",
                    key: item
                  }, [
                    _createVNode(_component_CardBlogPost, {
                      class: "mb-4 mb-md-0",
                      type: 'mini',
                      title: item.title,
                      thumbnail: item.thumbnail,
                      description: item.description,
                      author: { name: item.author.firstname + '  ' +  item.author.lastname, href: '/' },
                      details: {
              date: Date.parse(item.publishedAt),
              commentNo: item.comments.length,
              category: item.category.name,
            },
                      href: {
              name: 'Blog',
              params: {
                slug: item.slug,
              },
            }
                    }, null, 8, ["title", "thumbnail", "description", "author", "details", "href"])
                  ]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
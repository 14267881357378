
import { defineComponent, PropType } from "vue";
import types from "./BadgeTypes.json";
import {Position} from "../../types"

export default defineComponent({
  props: {
    text: {
      type: String,
      required: false,
    },
    bgColor: {
      type: String,
      required: false,
      default: "#22a6b3",
    },
    textColor: {
      type: String,
      required: false,
      default: "white",
    },
    position: {
      type: Object as PropType<Position>,
      default: () => {
        return {"none" : "none"};
      },
    },
    type: {
      type: String,
      required: false,
      default: 'none'
    },
  },
  computed: {
    getColors(): string {
      return this.type === 'none' || this.type
        ? "--badge-text-color:" +
            (types as any)[this.type].textColor +
            ";--badge-bg-color:" +
            (types as any)[this.type].bgColor
        : "--badge-text-color:" +
            this.textColor +
            ";--badge-bg-color:" +
            this.bgColor;
    },
  },
});

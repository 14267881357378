import { get,getSingle } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { Slot} from "../../types";
import { ref, computed, ComputedRef } from "vue";
import { find, meanBy } from "lodash";

export default function fetchHomeSlots(limit: number = 3) {
    const slots = ref<Slot>({} as Slot);
    const loading = ref<boolean>(true);
  
    const fetchSlots = () : Promise<void | Slot> => {
      return get<Slot>(GET.SLOTS,{"_limit": limit}).then((result) => {
        loading.value = false;
        slots.value = camelizeKeys(result);
      });
    };

    
  
    fetchSlots();
  
    return {
        slots,
        fetchSlots,
        loading
    };
  }


import { getSingle } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import {computed,ComputedRef,ref} from "vue";
import { Image } from "../../types";


type API_HomeTestimonial = {
  items:
Image[]
}

export default function fetchHomeTestimonial() {
  const testimonial = ref<API_HomeTestimonial>({} as API_HomeTestimonial);
  const loading = ref<boolean>(true);
  const fetchHomeTestimonial= () => {
    getSingle<API_HomeTestimonial>(GET.HOME_TESTIMONIALS).then((result) => {
      loading.value = false;
      testimonial.value = camelizeKeys(result);
    });
  };
  
  fetchHomeTestimonial();

  return{testimonial,loading,fetchHomeTestimonial}
}



import { defineComponent, PropType } from "vue";

import { MiniPost } from "@/types";

export default defineComponent({
  props: {
    title: {
      type: String,
    },
    posts: {
      type: Object as PropType<MiniPost[]>,
    },
  },
});

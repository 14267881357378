
import { PropType, defineComponent } from "vue";
import { Thumbnail } from "../../types";

export default defineComponent({
  props: {
    avatar: {
      type: Object as PropType<Thumbnail>,
    },
    author: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
});

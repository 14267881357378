
import { defineComponent, PropType } from "vue";
import Submenu from "../Submenu/Submenu.vue";
import { SocialLink, API_Menu_Link } from "../../types";
import Badge from "../Badge/Badge.vue";
import convertTextToIconType from "../../misc/helpers/convertTextToIconType";
import MobileMenu from "../../components/MobileMenu.vue";

export default defineComponent({
  components: {
    Submenu,
    Badge,
    MobileMenu,
  },
  props: {
    links: {
      type: Object as PropType<API_Menu_Link[]>,
      required: true,
    },
    socials: {
      type: Object as PropType<SocialLink[]>,
      required: false,
    },
    logo: {
      type: String,
    },
    siteName: {
      type: String,
    },
  },
  data() {
    return {
      showMobile: false,
    };
  },
  setup() {
    function removeBlankLink(link: string): string {
      if (link.includes("[blank]")) {
        return "";
      }
      return link;
    }
    function replaceBlankLink(link: string): string {
      if (link.includes("[blank]")) {
        return link;
      }
      return "";
    }
    return { convertTextToIconType, removeBlankLink, replaceBlankLink };
  },
});

export default {
    install: (app:any) => {
      app.config.globalProperties.$formatDate = (date : string|Date) => {
        const event: Date = date instanceof Date ? date as Date : new Date(date);
        const options : object = {
          // weekday: "short",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        return event.toLocaleDateString('en-US' , options);
      };
    },
  };
  
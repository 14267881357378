
import { defineComponent } from "vue";
import Characteristic from "../../components/Characteristic/Characteristic.vue";
import fetchHomeCharacteristics from "../../misc/composition/fetchHomeCharacteristics";
import SectionHeading from "../../components/SectionHeading/SectionHeading.vue";
import LoadingPageAnim from "../LoadingPageAnim.vue";
export default defineComponent({
  name: "Home",
  components: {
    Characteristic,
    SectionHeading,
    LoadingPageAnim
  },
  setup() {
    //
    const { characteristic, loading } = fetchHomeCharacteristics();

    return { characteristic, loading };
  },
});


import { defineComponent } from "vue";
import CardGame from "../../components/CardGame/CardGame.vue";
import fetchHomeSlots from "../../misc/composition/fetchHomeSlots";
import fetchHomeTitles from "../../misc/composition/fetchHomeSectionTitles";
import SectionHeading from "../../components/SectionHeading/SectionHeading.vue";
export default defineComponent({
  name: "Home",
  components: {
    CardGame,
    SectionHeading,
  },
  setup() {
    //
    const { slots, loading } = fetchHomeSlots();
    const { titles, loading: titleLoading } = fetchHomeTitles();
    return { slots, loading, titleLoading, titles };
  },
});


import { Thumbnail } from "../../types";
import { PropType } from "@vue/runtime-core";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    thumbnail: Object as PropType<Thumbnail>,
    rtl: {
      type: Boolean,
      default: false,
    },
  },
});

import {getSingle } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import {ref} from "vue";
import {Image} from "../../types"

type API_HOME_FAQ = {
  title:string,
  description:string
  thumbnail:
  Image[]
}

export default function fetchHomeFAQ() {
  const faq = ref<API_HOME_FAQ>({} as API_HOME_FAQ);
  const loading = ref<boolean>(true);

  const fetchFAQ= () => {
    getSingle<API_HOME_FAQ>(GET.HOME_FAQ).then((result) => {
      loading.value = false;
      faq.value = camelizeKeys(result);
    });
  };
  fetchFAQ();
  return{faq,loading,fetchFAQ}
}

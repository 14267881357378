
import { defineComponent, PropType } from "vue";
import SectionHeading from "../../components/SectionHeading/SectionHeading.vue";
import CardInfo from "../../components/CardInfo/CardInfo.vue";
import fetchHomeInfo from "../../misc/composition/fetchHomeInfo";
import { Icon } from "@/types";
import convertTextToIconType from "../../misc/helpers/convertTextToIconType";

export default defineComponent({
  name: "Home",
  components: {
    CardInfo,
    SectionHeading,
  },
  icon: {
    type: Object as PropType<Icon>,
    required: true,
  },
  setup() {
    const { info, loading } = fetchHomeInfo();
    return { info, loading, convertTextToIconType };
  },
});

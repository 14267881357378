import { get,getSingle } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { Casino} from "../../types";
import { ref, computed, ComputedRef } from "vue";
import { find, meanBy } from "lodash";

export default function fetchHomeOnlineCasino(limit: number=6) {
    const newcasinos = ref<Casino>({} as Casino);
    const loading = ref<boolean>(true);
  
    const fetchOnelineCasino = () : Promise<void | Casino> => {
      return get<Casino>(GET.CASINOS,{"_limit": limit}).then((result) => {
        loading.value = false;
        newcasinos.value = camelizeKeys(result);
      });
    };

    
  
    fetchOnelineCasino();
  
    return {
      newcasinos,
      fetchOnelineCasino,
      loading
    };
  }
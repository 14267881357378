import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-ce2bf18e")
const _hoisted_1 = { class: "subtitle" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "description" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.subtitle), 1),
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.description), 1)
    ]),
    _renderSlot(_ctx.$slots, "action", {}, () => [
      (_ctx.buttonName)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            type: "link",
            class: "button general-btn wide no-anim",
            href: _ctx.href
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.buttonName), 1)
            ]),
            _: 1
          }, 8, ["href"]))
        : _createCommentVNode("", true)
    ], {}, true)
  ]))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7dbb6612")
const _hoisted_1 = { class: "m-0" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_collapse_panel = _resolveComponent("a-collapse-panel")!
  const _component_a_collapse = _resolveComponent("a-collapse")!

  return (_openBlock(), _createBlock(_component_a_collapse, {
    activeKey: _ctx.activeKey,
    "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeKey = $event)),
    accordion: "",
    "expand-icon-position": "right",
    class: "collapse-wrapper"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
        return (_openBlock(), _createBlock(_component_a_collapse_panel, {
          key: i,
          header: item.question
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_1, _toDisplayString(item.answer), 1)
          ]),
          _: 2
        }, 1032, ["header"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["activeKey"]))
}
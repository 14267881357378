
import { defineComponent } from "vue";
import CardBlogPost from "../../components/CardBlogPost/CardBlogPost.vue";
import fetchLatestNews from "../../misc/composition/fetchLatestNews";
import fetchHomeTitles from "../../misc/composition/fetchHomeSectionTitles";
import SectionHeading from "../../components/SectionHeading/SectionHeading.vue";
export default defineComponent({
  name: "Home",
  components: {
    CardBlogPost,
    SectionHeading,
  },
  setup() {
    const { latestNews, loading } = fetchLatestNews(4);
    const { titles, loading: titleLoading } = fetchHomeTitles();
    return { latestNews, loading, titleLoading, titles };
  },
});
